import React from "react";
import Container from "../Grid/Container";
import theme from "../theme";
import NavMenu from "./Nav/NavMenu";
import DesktopNav from "./Nav/DesktopNav";
import MobileNav from "./Nav/MobileNav";

import TopHeaderNotifications from "./TopHeaderNotifications";
import ForceUserProfileUpdate from "../../../general/ForceUserProfileUpdate";
import { AuthorizationService } from "../../../../service/AuthorizationService";
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTrigger,
} from "../../../ui/sheet";
import MobileNavPrevious from "./Nav/MobileNavPrevious";
import MobileNavTogglerPrevious from "./Nav/MobileNavTogglerPrevious";
import { Menu, X } from "lucide-react";

type Props = {
    currentRouteKey?;
    user?;
    authorizationService?: AuthorizationService;
    dock?: boolean;
};

type State = {
    showNav;
};
class Header extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = { showNav: false };
    }

    render() {
        const {
            currentRouteKey,
            user,
            authorizationService,
            dock = false,
        } = this.props;
        const isLoggedIn = !!user;

        const darkTheme = isLoggedIn;
        const logoUrl = isLoggedIn
            ? "/static/logo-icon-dark.svg"
            : "/static/logo-icon-alpha.svg";

        return (
            <React.Fragment>
                <ForceUserProfileUpdate user={user} />
                <TopHeaderNotifications user={user} />
                <TopNavigation
                    isLoggedIn={isLoggedIn}
                    logoUrl={logoUrl}
                    currentRouteKey={currentRouteKey}
                    user={user}
                    authorizationService={authorizationService}
                    darkTheme={darkTheme}
                    dock={dock}
                />
            </React.Fragment>
        );
    }
}

function TopNavigation({
    isLoggedIn,
    logoUrl,
    currentRouteKey,
    user,
    authorizationService,
    darkTheme,
    dock,
}: {
    isLoggedIn: boolean;
    logoUrl: string;
    currentRouteKey: any;
    user: any;
    authorizationService: AuthorizationService;
    darkTheme: boolean;
    dock: boolean;
}) {
    const [showNav, setShowNav] = React.useState(false);
    const dockClass = dock
        ? isLoggedIn
            ? "tw-bg-user"
            : "tw-bg-secondary"
        : "";
    return (
        <div
            className={`top-navigation tw-items-end tw-flex tw-flex-col tw-w-full ${dockClass}`}
        >
            <div className="container tw-w-full">
                <nav
                    className={`tw-mt-6 tw-pr-4 ${
                        isLoggedIn ? "tw-bg-user" : "tw-bg-secondary"
                    } tw-rounded tw-shadow-md`}
                >
                    <a href="/" className={"logo tw-rounded"}>
                        <h1 className={"sr-only"}>
                            Convergence Blended Finance
                        </h1>
                        <img
                            src={logoUrl}
                            className="logo-image tw-pl-4 tw-rounded"
                            alt={"Convergence"}
                        />
                    </a>
                    <Sheet onOpenChange={() => setShowNav(!showNav)}>
                        <SheetTrigger className="lg:tw-hidden">
                            {!showNav ? (
                                <Menu size={38} color="white" />
                            ) : (
                                <X size={38} color="white" />
                            )}
                        </SheetTrigger>
                        <SheetContent
                            side="top"
                            className="container !tw-p-0 lg:tw-px-[15px] tw-rounded md:tw-inset-x-0 tw-inset-x-0 tw-w-auto !tw-text-left"
                        >
                            <SheetHeader></SheetHeader>
                            <MobileNav
                                currentRouteKey={currentRouteKey}
                                user={user}
                                authorizationService={authorizationService}
                                darkTheme={darkTheme}
                            />
                        </SheetContent>
                    </Sheet>
                    <NavMenu
                        currentRouteKey={currentRouteKey}
                        user={user}
                        // darkTheme={darkTheme}
                        authorizationService={authorizationService}
                    />
                </nav>
            </div>
            <style jsx>{`
                .top-navigation {
                    position: sticky;
                    top: -31px;
                    z-index: 100;
                    // height: 80px;

                    // box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
                    // inset 0 -1px 0 0 #dadce0;
                }

                .logo {
                    overflow: hidden;
                    display: inline-block;
                    // padding: 0.25rem;
                    height: 60px;
                    min-width: 95px;
                    // justify-content: flex-start;
                }

                .logo-image {
                    // height: 70px;
                    //     padding-right: 1rem;
                }

                nav {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    // align-items: stretch;
                    // height: 60px;
                    width: 100%;
                }

                .main-menu {
                    justify-content: flex-start;
                    font-weight: normal;
                    font-size: 1rem;
                    white-space: nowrap;
                    overflow-y: auto;
                    padding: 0.5rem 0;
                    -webkit-font-smoothing: antialiased;
                }

                .right-menu {
                    margin-left: auto;
                    color: ${theme.colour.white};
                    justify-content: flex-end;
                    font-weight: normal;
                    font-size: 0.9rem;
                    padding: 1rem 0;
                    -webkit-font-smoothing: antialiased;
                }
                // @media (min-width: ${theme.breakpoint.lg}) {
                .logo,
                .logo-image,
                nav {
                    height: 3.5rem;
                    display: flex;
                }
                // }
            `}</style>
        </div>
    );
}

export default Header;
